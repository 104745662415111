import '@babel/polyfill';
import React, { Component } from 'react';
import { render } from 'react-dom';
import { Card } from 'react-bootstrap';
import { getPlayer, playerNames } from './helpers/Users';
import LoginForm from './components/LoginForm';
import SandwichForm from './components/SandwichForm';
import axios from "axios";

const emptySandwich = {
  player: '',
  meat: '',
  bread: '',
  cheese: '',
  vegetables: [],
  condiments: [],
  id: null
};

// const cloneSandwich = sandwich => {
//   return {
//     ...sandwich,
//     ...{ vegetables: { ...sandwich.vegetables }},
//     ...{ condiments: { ...sandwich.condiments }}
//   }
// }

const defaultPlayer = getPlayer(playerNames[0]);

class App extends Component {

  constructor (props) {
    super(props);
    this.state = {
      sandwich: {...emptySandwich},
      loginObj: {
        playerObj: defaultPlayer,
        loggedIn: false,
        password: ''
      }
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSandwichSubmit = this.handleSandwichSubmit.bind(this);
    this.updateSandwichItem = this.updateSandwichItem.bind(this);
    this.updateLoginObj= this.updateLoginObj.bind(this);
  }

  handleLogin ({ playerObj, sandwich }) {
    this.setState(prevState => {
      sandwich = sandwich ? { ...sandwich } : { ...prevState.sandwich };
      sandwich = { ...sandwich, ...{ player: playerObj.name, id: playerObj.id }};
      const newState = {
        ...{ loginObj: {
            playerObj,
            loggedIn: true,
            password: prevState.loginObj.password
          }},
        sandwich
      };
      return newState;
    });
  }

  handleLogout () {
    /*
     * The first player name is the placeholder
     */
    const playerObj = getPlayer(playerNames[0]);
    this.setState({
      loginObj: {
        playerObj,
        loggedIn: false,
        password: ''
      },
      sandwich: {...emptySandwich}
    });
  }

  handleSandwichSubmit (evt) {
    evt.preventDefault();
    const { state } = this;
    const { sandwich, loginObj } = state;
    const { loggedIn } = loginObj;
    if (!loggedIn) {
      window.alert('You must log in first');
      return;
    }
    sandwich.id = loginObj.playerObj.id;
    sandwich.player = loginObj.playerObj.name;
    const formData = { ...sandwich};
    console.log(JSON.stringify(formData, null, 2));
    const url = `${origin}/sandwich/save`
    axios.post(url, formData)
      .then(function (response) {
        console.log(response);
        alert('Sandwich saved')
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateSandwichItem (sandwichItem) {
    const { state } = this;
    const { sandwich } = state;
    this.setState({
      sandwich: {
        ...sandwich,
        ...sandwichItem
      }
    })
  }

  updateLoginObj(loginObj) {
    this.setState(
      { loginObj}
    );
  }

  render () {
    const { loginObj, sandwich } = this.state;
    const { loggedIn } = loginObj;
    const { handleLogin, handleLogout, updateLoginObj, updateSandwichItem, handleSandwichSubmit } = this;
    const sandwichProps = { loggedIn, updateSandwichItem, handleSandwichSubmit };
    return (
      <div>
        <Card key={1} id="login-card" className="login-card">
          <Card.Title>Who Are We?  D.N.A.</Card.Title>
          <Card.Body>
            <LoginForm {...loginObj} updateLoginObj={updateLoginObj} handleLogin={handleLogin} handleLogout={handleLogout}/>
          </Card.Body>
        </Card>
        <Card key={2} id="sandwich-card" className="sandwich-card">
          <Card.Title>Create Your Sandwich</Card.Title>
          <Card.Body>
            <SandwichForm {...sandwich} {...sandwichProps} />
          </Card.Body>
        </Card>
      </div>
    );
  }

}

render(<App />, document.getElementById('root'));