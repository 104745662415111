import React from 'react';
import PropTypes from 'prop-types';

const Input = (props) => {
  const classes = props.className ? props.className : '';
  const classNames = `form-group ${classes}`;
  return (
    <div className={classes}>
      <label htmlFor={props.name} className="form-label">{props.title}</label>
      <input
        className="form-input"
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
      />
    </div>
  )
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default Input;
