import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';
import CheckBox from './CheckBox';

class SandwichForm extends Component {

  constructor (props) {
    super(props);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.items = ['meat', 'bread', 'cheese', 'vegetables', 'condiments'];
    this.meat = {
      name: 'meat',
      type: 'radio',
      title: 'Choose your MEAT:',
      handleChange: this.handleItemChange,
      options: ['Turkey Breast', 'Black Forest Ham', 'Roast Beef', 'Salami'],
      selected: props.meat
    };
    this.bread = {
      name: 'bread',
      type: 'radio',
      title: 'Choose your BREAD:',
      handleChange: this.handleItemChange,
      options: ['Oat Nut (Almost White)', 'Multi-Grain', 'Whole Wheat'],
      selected: props.bread
    };
    this.cheese = {
      name: 'cheese',
      type: 'radio',
      title: 'Choose your CHEESE:',
      handleChange: this.handleItemChange,
      options: ['Swiss', 'Pepper Jack', 'Provolone', 'Munster'],
      selected: props.cheese
    };
    this.vegetables = {
      name: 'vegetables',
      type: 'checkbox',
      title: 'Choose any VEGETABLE(S):',
      handleChange: this.handleItemChange,
      options: ['Lettuce', 'Tomatoes', 'Sliced Onion', 'Bell Peppers', 'Pepperocini', 'Pickles'],
      selected: props.vegetables
    };
    this.condiments = {
      name: 'condiments',
      type: 'checkbox',
      title: 'Choose any CONDIMENT(S):',
      handleChange: this.handleItemChange,
      options: ['Yellow Mustard', 'Stone Ground Mustard', 'Mayonaise', 'Spicy Mayonaise', 'Salt', 'Pepper', 'Butter'],
      selected: props.condiments
    };
  }

  /*
   * Checkboxes and Radio Inputs must not use evt.preventDefault because the default action
   * is needed to update the HTMLElement.
   */

  handleItemChange (evt) {
    const { props } = this;
    const { loggedIn, updateSandwichItem } = props;
    if (!loggedIn) {
      window.alert('You must log in first');
      return;
    }
    const target = evt.target;
    const name = target.name;
    const value = target.value;
    const type = this[name].type;
    let sandwichItem;
    if (type === 'radio') {
      sandwichItem = { [name]: value };
    } else if (type === 'checkbox') {
      const lastValue = this[name].selected;
      if (lastValue.includes(value)) {
        const newValue = lastValue.filter(v => v !== value);
        sandwichItem = {
          [name] : newValue
        };
      } else {
        sandwichItem = {
          [name] : [
            ...lastValue,
            value
          ]
        };
      }
    }
    updateSandwichItem(sandwichItem);
  }

  render () {
    const { meat, bread, cheese, vegetables, condiments} = this;
    const { props, items } = this;
    items.forEach(item => {
      this[item].selected = props[item];
    });
    const formSubmitHandler = props.handleSandwichSubmit;
    return (
      <form className="container sandwich-form" onSubmit={formSubmitHandler}>
        <Radio className="meat" {...meat} />
        <Radio className="bread" {...bread} />
        <Radio className="cheese" {...cheese} />
        <CheckBox className="vegetables" {...vegetables} />
        <CheckBox className="condiments" {...condiments} />
        <button className="btn btn-primary" type="submit">Submit</button>
      </form>
    );
  };
}

SandwichForm.propTypes = {
  updateSandwichItem: PropTypes.func.isRequired,
  handleSandwichSubmit: PropTypes.func.isRequired,
  player: PropTypes.string.isRequired,
  meat: PropTypes.string.isRequired,
  bread: PropTypes.string.isRequired,
  cheese: PropTypes.string.isRequired,
  vegetables: PropTypes.array.isRequired,
  condiments: PropTypes.array.isRequired
};

export default SandwichForm;