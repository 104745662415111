import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Input from './Input';
import Select from './Select';
import { getPlayer, playerNames } from "../helpers/Users";

const origin = window.location.origin;

class LoginForm extends Component {

  constructor (props) {
    super(props);
    this.handlePlayerChange = this.handlePlayerChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.playerSelect = {
      name: 'player',
      title: 'Select your name from the drop-down box',
      placeholder: 'Choose from drop-down box',
      handleChange: this.handlePlayerChange,
      options: playerNames,
      value: ''
    };
    this.passwordInput = {
      name: 'password',
      type: 'password',
      title: 'password',
      handleChange: this.handlePasswordChange,
      value: ''
    };
  }

  /*
 * Checkboxes and Radio Inputs must not use evt.preventDefault
 */
  handlePlayerChange (evt) {
    const { props, logout, passwordInput } = this;
    const { playerObj, loggedIn, updateLoginObj} = props;
    if (loggedIn) {
      const name = playerObj.name;
      const password = passwordInput.value;
      const answer = window.confirm(`Logout ${name}?`);
      if (!answer) return;
      logout({ name, password });
    } else {
      const name = evt.target.value;
      const playerObj = getPlayer(name)
      updateLoginObj({
        playerObj,
        loggedIn: false,
        password: ''
      })
    }
  };

  handlePasswordChange (evt) {
    const password = evt.target.value;
    const { props } = this;
    const { playerObj, loggedIn, updateLoginObj} = props;
    updateLoginObj( {
      playerObj,
      loggedIn,
      password
    })
  };

  formSubmitHandler (evt) {
    evt.preventDefault();
    const { props, login, logout } = this;
    const { playerObj, loggedIn, password } = props;
    const { name } = playerObj;
    const formData = {
      name,
      password
    };
    if (!loggedIn) {
      login(formData);
    } else {
      logout(formData);
    }
  }

  login (formData) {
    const { props } = this;
    const { playerObj, handleLogin } = props;
    const url = `${origin}/login`;
    axios.post(url, formData)
      .then(function (response) {
        const sandwich = response.data
        console.log(sandwich);
        handleLogin({ playerObj, sandwich });
      })
      .catch(function (error) {
        console.log(error);
        alert('Please re-enter your password');
      });
  }

  logout (formData) {
    const { props } = this;
    const { handleLogout } = props;
    const url = `${origin}/login/logout`;
    axios.post(url, formData)
      .then(function (response) {
        handleLogout();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render () {
    const { playerSelect, passwordInput } = this;
    const { props, formSubmitHandler } = this;
    const { playerObj, loggedIn, password } = props;
    playerSelect.value = playerObj.name;
    passwordInput.value = password;
    const buttonLabel = loggedIn ? 'Sign Out' : 'Submit';
    return (
      <div className="loginForm">
        <form className="container" onSubmit={formSubmitHandler}>
          <Select className="login-player" {...playerSelect} />
          <div>(See GroupMe about your password)</div>
          <Input className="password" {...passwordInput} />
          <button className="btn btn-primary" type="submit">{buttonLabel}</button>
        </form>
      </div>
    )
  }
}

LoginForm.propTypes = {
  updateLoginObj: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  playerObj: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired
};

export default LoginForm;
