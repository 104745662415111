import * as PlayersModule from "../components/Players";

const Players = PlayersModule.default;
const playerArray = Players.Players;
const playerNames = playerArray.map(p => p.name);

const getPlayer = name => {
  const filtered = playerArray.filter(p => p.name === name);
  const player = filtered.length ? filtered[0] : {};
  return player;
}

export { getPlayer, playerNames };
