import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = (props) => {
  const classes = props.className ? props.className : '';
  const classNames = `form-group ${classes}`;

  return( <div className={classNames}>
      <label htmlFor={props.name} className="form-label">{props.title}</label>
      <div className="checkbox-group">
        {props.options.map((option, i) => {
          return (
            <div key={i}>
              <label key={option}>
                <input
                  className="form-checkbox"
                  id={`${props.name}-${i}`}
                  name={props.name}
                  onChange={props.handleChange}
                  value={option}
                  checked={ props.selected.includes(option) }
                  type="checkbox"
                /> {option}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );

};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CheckBox;