const Players = [
  {id: 0, name: 'Choose your name', jersey: -1},
  {id: 1, name: 'David Bacon-Caldera', jersey: 78},
  {id: 2, name: 'Raymond Bacon-Caldera', jersey: 87},
  {id: 3, name: 'Tohn Boulom', jersey: 8},
  {id: 4, name: 'RSG jr', jersey: 4},
  {id: 5, name: 'Keith Hartman', jersey: 6},
  {id: 6, name: 'Timothy Ho', jersey: 1},
  {id: 7, name: 'Jack Hurtado-Jimenez', jersey: 16},
  {id: 8, name: 'Lee Hurtado-Jimenez', jersey: 15},
  {id: 9, name: 'Darryl Kaminski', jersey: -1},
  {id: 10, name: 'Sue Kline', jersey: 20},
  {id: 11, name: 'John Krumm', jersey: 24},
  {id: 12, name: 'Kevin Lewis', jersey: 88},
  {id: 13, name: 'Russell Lim', jersey: -1},
  {id: 14, name: 'Pablo Molina', jersey: 21},
  {id: 15, name: 'Stefan Musarra', jersey: 0},
  {id: 16, name: 'William Odom-Green', jersey: 9},
  {id: 17, name: 'Javier Peraza', jersey: 2},
  {id: 18, name: 'Andy Phun', jersey: -1},
  {id: 19, name: 'Devin Stine', jersey: -1},
  {id: 20, name: 'Fan #1', jersey: 1},
  {id: 21, name: 'Fan #2', jersey: 2},
  {id: 22, name: 'Fan #3', jersey: 3},
  {id: 23, name: 'Fan #4', jersey: 4},
  {id: 24, name: 'Fan #5', jersey: 5},
  {id: 25, name: 'Fan #6', jersey: 6},
  {id: 26, name: 'Fan #7', jersey: 7},
  {id: 28, name: 'Fan #8', jersey: 8},
  {id: 29, name: 'Fan #9', jersey: 9},
  {id: 30, name: 'Fan #10', jersey: 10}
];

// export default Players;
// exports.default = Players;
module.exports = { Players };

